import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GiBookshelf } from 'react-icons/gi';

import Logo from './Logo';
import NavMenu from './NavMenu';

/**
 * Componente para la cabecera de las páginas.
 */
const Header = (props) => {
    return (
        <Wrapper>
            <Logo siteTitle={props.siteTitle} />
            <Separator>
                <GiBookshelf size="32"/>
            </Separator>
            <NavMenu />
        </Wrapper>
    );
}

// Estilo de la cabecera
const Wrapper = styled.div`
    display: flex;
    align-items: baseline;
    width: 640px;
    min-width: 300px;
    margin: 2rem auto;
    /*position: relative;*/
    text-align: center;

    @media (max-width: 800px) {
        width: 60%;
    }
`;

// Componente de separador
const Separator = styled.span`
    display: inline;
    /*border-left: 1px solid var(--clr-grey-5);*/
    margin: 0 auto;
    height: 100%;

    @media (max-width: 800px) {
        display: none;
    }
`;

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;