import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { IoMdMenu } from 'react-icons/io';

import Links from './Links';
import SideMenu from './SideMenu';
import Boton from '../Boton';

/**
 * Componente del menú superior de navegación.
 * 
 * Este componente muestra el menú abierto (los enlaces) cuando hay suficiente
 * espacio horizontal y el icono de la hamburguesa cuando no hay tanto
 * espacio.
 */
const NavMenu = (props) => {
    // Estado del menú de navegación
    const [isOpen, setIsOpen] = useState(false);

    // Renderización
    return (
        <Wrapper>
            <Backdrop isOpen={isOpen}/>
            <Boton className="menuButton" onClick={() => setIsOpen(!isOpen)}>
                <IoMdMenu />
            </Boton>
            <Links className="navBarLinks" />
            <SideMenu isOpen={isOpen} onClick={() => setIsOpen(!isOpen)} />
        </Wrapper>
    );
}

// Estilo del componente
const Wrapper = styled.div`
    display: inline;
    margin: 0;
    font-family: var(--ff-menu);
    font-size: var(--fsz-menu);
    font-weight: 300;

    .menuButton {
        display: none;
    }

    .navBarLinks {
        display: flex;
        flex-flow: row nowrap;
        margin: 0px;

        a, a:visited {
            color: var(--clr-principal);
            text-decoration: none;
        }

        button {
            margin: 0 1rem;
            padding: 0.3rem 0.5rem;
        }
        /*li {
            display: block;
            border: none;
            border-radius: 10px;
        }

        li:hover {
            background-color: var(--clr-hover);
        }*/
    }

    @media (max-width: 800px) {
        display: flex;
        margin-left: auto;

        .navBarLinks {
            display: none;
        }

        .menuButton {
            display: flex;
        }
    }
`;

// Componente que oculta la parte de la página mientras se muestra
// el menú lateral.
const Backdrop = styled.div`
    display: flex;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: black;
    opacity: 0.5;
    visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
    transform: ${({ isOpen }) => isOpen ? 'opacity(0.5)' : 'opacity(0)'};
    transition: visibility 0s, opacity 0.5s linear;
`;

// Definición de las propiedades necesarias
NavMenu.propTypes = {

};

export default NavMenu;
