import React from 'react';
import { IoMdClose } from 'react-icons/io';
import styled from 'styled-components';

import Links from './Links';
import Boton from '../Boton';

/**
 * Componente del menú lateral de navegación.
 * 
 * Este componente sólo aparece cuando el navegador es estrecho y se pulsa
 * sobre el menú de hamburguesa. Por eso, el DIV principal (el 'aside')
 * tiene un 'template literal' para poner una variable.
 */
const SideMenu = (props) => {

    // Mostramos el menú lateral condicionalmente
    // const asideClass = `${props.isOpen ? "showSidebar" : "hideSidebar"}`;

    return (
        <Wrapper isOpen={props.isOpen}>
            <Boton className="closeButton" onClick={props.onClick}>
                <IoMdClose size="32"/>
            </Boton>

            <Links className="sideBarLinks" />
        </Wrapper>
    );
}

// Estilo del componente
const Wrapper = styled.aside`
    height: 100%;
    width: 200px;
    min-width: 150px;
    padding-top: 0.5rem;
    padding-left: 0;

    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;

    display: ${({ isOpen }) => isOpen ? "flex" : "none"};
    flex-flow: column;

    background-color: var(--clr-white);
    
    transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
    transition: transform 0.3s ease-in-out;

    .sideBarLinks {
        font-family: var(--ff-menu);
        font-size: larger;
        margin: 0;
    }

    .closeButton {
        margin-left: auto;
    }
`;

// Componente para mostrar el fondo cuando 

export default SideMenu;
