import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import styled from 'styled-components';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

// Consulta para obtener los metadatos de la página
const siteQuery = graphql`
    query SiteMetadataQuery {
        site {
            siteMetadata {
            title,
            author
            }
        }
    }`;

/**
 * Componente de disposición común de las páginas.
 */
const Layout = (props) => {
    // Consulta de metadatos
    const data = useStaticQuery(siteQuery);

    // Renderizado
    return (
        <Wrapper>
            <Header siteTitle={data.site.siteMetadata.title} />
            <main>{props.children}</main>
            <Footer author={data.site.siteMetadata.author} />
        </Wrapper>
    );
}

// Estilo del componente de Layout
const Wrapper = styled.div`
  margin-bottom: 1.25rem;

  main {
    width: 60%;
    max-width: 800px;
    min-width: 300px;
    margin: 2rem auto;
  }
`

export default Layout;