import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import Boton from '../Boton';

/**
 * Componente para mostrar los enlaces de las barras de navegación
 * (la lateral y la superior).
 * 
 * Quito la parte de libros por ahora
 */
const Links = (props) => {
  return (
    <Ul {...props}>
      <li>
        <Boton onClick={() => navigate('/')} >
          Inicio
        </Boton>
      </li>
      <li>
        <Boton onClick={() => navigate('/bio')} >
          Bio
        </Boton>
      </li>
    </Ul>
  )
}
/*
      <li>
        <Boton onClick={() => navigate('/poesia')} >
          Poesia
        </Boton>
      </li>
<li>
<Boton onClick={() => navigate('/libros')} >
  Libros
</Boton>
</li>
*/

// Estilo de la lista de enlaces
const Ul = styled.ul`
  list-style: none;
`;


export default Links;