import React from 'react';
import styled from 'styled-components';

/**
 * Componente de botón para probar.
 */
const Boton = (props) => {

    return (
        <StyledButton onClick={props.onClick} {...props}>
            {props.children}
        </StyledButton>
    );
};

// Componente para aplicar el estilo
const StyledButton = styled.button`
    cursor: pointer;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: var(--clr-white);

    :hover {
        background-color: var(--clr-primary-9);
    }

    :focus {
        outline: none;
    }
`;

export default Boton;