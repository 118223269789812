import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Logotipo que va en la cabecera (en texto).
 */
const Logo = (props) => {
    return (
        <Wrapper>{props.siteTitle}</Wrapper>
    );
}

// Estilo del logotipo (texto)
const Wrapper = styled.span`
    display: inline-block;
    font-family: var(--ff-banner);
    font-size: var(--fsz-title);
    color: var(--clr-grey-1);
    margin-right: 1.666rem;
    font-weight: 900;

    @media (max-width: 800px) {
        font-size: var(--fsz-title-narrow);
    }
`;

Logo.propTypes = {
    siteTitle: PropTypes.string,
};

Logo.defaultProps = {
    siteTitle: ``
};

export default Logo;