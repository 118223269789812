import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/**
 * Componente para el pié de página.
 */
const Footer = (props) => {
    return (
        <Wrapper>
            © {new Date().getFullYear()} {props.author}
        </Wrapper>
    );
}

// Estilo para el componente
const Wrapper = styled.footer`
    position: fixed;
    bottom: 0.5rem;
    width: 100%;
    margin: 0px auto;
    text-align: center;
    font-size: small;
    font-family: var(--ff-menu);
    z-index: 1;
`

// Parámetros necesarios
Footer.propTypes = {
    author: PropTypes.node.isRequired,
};

export default Footer;
